import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import DownloadBtn from "../components/DownloadBtn"
import TitlePage from "../components/TitlePage"

export default function Validations({ location }) {
  const path = [
    { label: "Inicio", to: "/" },
    {
      label: "Certificaciones - Reconocimientos con otras certificaciones",
    },
  ]
  return (
    <Layout>
      <TitlePage
        title="Reconocimientos con otras certificaciones"
        url={location.pathname}
      />
      <Hero image="/imgs/certificaciones-hero.jpg" text="Certificaciones" />
      <div className="container950">
        <div className="Validations">
          <Breadcrumb path={path} />
          <TitleBlock
            title="Reconocimientos con otras certificaciones"
            icon="fas fa-file-alt"
          />
          <div className="efpa-content">
            <TitleBlock
              title="Reconocimiento para acceder al examen EFP - European Financial Planner"
              icon="fas fa-project-diagram"
              className="Certification-title-block mb-4"
            />
            <span className="Certification-text mt-3 mb-4">
              - Reconocimientos de otras titulaciones como requisitos para el
              acceso al examen EFPA European Financial Planner (EFP):
            </span>
            <DownloadBtn
              text="Reconocimiento EFP"
              link="/docs/convalidacionEFP.pdf"
            />
          </div>
          <div className="efpa-content">
            <TitleBlock
              title="Reconocimiento para disponer el certificado European Financial Advisor (EFA)"
              icon="fas fa-user-tie"
              className="Certification-title-block mb-4"
            />
            <span className="Certification-text mt-3 mb-4">
              - Reconocimiento de la certificación Professional Diploma in
              Financial Advice, QFA (Irlanda) para disponer del certificado
              European Financial Advisor (EFA) en España:
            </span>
            <DownloadBtn
              text="Reconocimiento Professional Diploma in Financial Advice, QFA"
              link="/docs/Reconocimientoqfa.pdf"
            />
            <span className="Certification-text mt-3 mb-4">
              - Acceso a los exámenes EFPA, Nivel II EFA disponiendo de otras
              titulaciones y certificaciones profesionales
            </span>
            <DownloadBtn
              text="Reconocimiento de requisitos para el acceso al examen NIVEL II, EFA (European Financial Advisor)"
              link="/docs/reconocimiento-NIVELII-EFA.pdf"
            />
          </div>
          <div className="efpa-content">
            <TitleBlock
              title="Organismo Regulador Mejicano AMIB"
              icon="fas fa-file-alt"
            />
            <span className="Certification-text mt-3 mb-2">
              - Reconocimiento de las licencias del organismo Regulador Mejicano
              AMIB:
              <br />
              <br />
              <p>
                <a
                  className="Certification-link"
                  href="https://efpa.es/actualidad/14/noticias-efpa/2155/los-miembros-certificados-de-efpa-espana-podran-obtener-licencias-de-asesoramiento-en-mexico/"
                >
                  ¿Cómo debe realizarse el proceso?
                </a>
              </p>
              Guía de Certificación Nivel II (EFA) - México:
            </span>
            <DownloadBtn
              text="Guía de certificación México"
              link="/docs/EFA-NivelII-guia_certificacion_mexico.pdf"
            />
          </div>
          <div className="efpa-content">
            <TitleBlock
              title=" Comité de Acreditación de Conocimientos en el Mercado de Valores (CAMV)"
              icon="fas fa-file-alt"
            />
            <span className="Certification-text mt-3 mb-2">
              <p>
                Los miembros certificados de EFPA España, podrán optar a la
                acreditación de mercado de valores, para ejercer asesorías de
                inversión en Chile y viceversa.
              </p>
              <p>
                Los asesores financieros Chilenos, certificados por CAMV, podrán
                obtener la certificación EIP de EFPA España, tras acreditar 30
                horas de formación continua o superar un curso de Marco Legal y
                Fiscal Español.
              </p>
              <p>
                <a
                  className="Certification-link"
                  href="https://www.efpa.es/actualidad/1/noticias/2758/los-miembros-certificados-de-efpa-espana-podran-obtener-licencias-de-asesoramiento-en-chile-y-viceversa/"
                >
                  Leer acuerdo
                </a>
              </p>
            </span>
          </div>
          <div className="efpa-content">
            <TitleBlock
              title="Convenio de Colaboración con la Bolsa de Valores de Nicaragua"
              icon="fas fa-file-alt"
            />
            <span className="Certification-text mt-3 mb-2">
              <p>
                Las personas interesadas en obtener esta certificación han de
                solicitar a través de la Bolsa de Valores de Nicaragua la
                inscripción para realizar el curso online de preparación para el
                examen.
              </p>{" "}
              <p>
                Una vez aprobado dicho curso, realizar la inscripción para el
                examen formal para la obtención de la certificación
                internacional para asesores financieros IPP.
              </p>
              <p>
                <a
                  className="Certification-link"
                  href="https://www.efpa.es/actualidad/14/noticias-efpa/3228/convenio-de-colaboracion-entre-efpa-espana-y-la-bolsa-de-valores-de-nicaragua"
                >
                  Leer acuerdo
                </a>
              </p>
            </span>
          </div>
        </div>
      </div>
    </Layout>
  )
}
